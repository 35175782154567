import { useState, useRef } from "react"
import PlayButton from "public/assets/play-button.svg"
import cn from "classnames"
import Script from "next/script"

export default function DynamicVideo({ component, ...videoFields }) {
  switch (component) {
    case "video_embed":
      return <VideoEmbed {...videoFields} />
    case "yt_embed":
      return <YTEmbed {...videoFields} />
    default:
      return "Invalid video type"
  }
}

function VideoEmbed({ video_asset, thumbnail, title, description, _uid }) {
  const [videoPlaying, setVideoPlaying] = useState(false)
  const videoRef = useRef(null)

  let type = `video/${video_asset.filename.match(/.(mov|mp4)/gi)[0]}`.replace(".", "")
  switch (type) {
    case "video/mov":
      type = "video/mp4"
      break
    case "video/mp4":
      type = "video/mp4"
      break
    default:
      type = "invalid"
      break
  }

  const thumbnailURL = thumbnail?.filename ? thumbnail?.filename : "/assets/placeholder.png"

  return (
    <>
      <div className="relative mx-auto">
        <button
          onClick={() => {
            setVideoPlaying(!videoPlaying)
            videoPlaying === true ? videoRef.current.pause() : videoRef.current.play()
          }}
          className={cn("absolute scale-75 sm:scale-100 top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 z-10", {
            hidden: videoPlaying,
          })}
          title="Play video"
        >
          <PlayButton className="play-video text-secondary hover:text-tertiary" />
        </button>
        <video
          ref={videoRef}
          poster={thumbnailURL}
          src={video_asset.filename}
          className="video-embed w-full aspect-video object-cover"
          type={type}
          controls
          onPlay={() => setVideoPlaying(true)}
          onPause={() => setVideoPlaying(false)}
        />
      </div>
      <Script type="application/ld+json" id={`embed-video-schema-${_uid}`}>
        {`{
          "@context": "https://schema.org",
          "@type": "VideoObject",
          "name": ${title ? JSON.stringify(title) : "Embed Video"},
          "description": "${description ?? ""}",
          "thumbnailUrl": "${thumbnailURL}",
          "uploadDate": "${new Date()}",
          "contentUrl": "${video_asset.filename}"
        }`}
      </Script>
    </>
  )
}

function YTEmbed({ id, thumbnail, title, description, _uid }) {
  const [videoPlay, setVideoPlay] = useState(false)
  const YTVideoStyles = "aspect-video cursor-pointer w-full"
  const thumbnailURL = thumbnail?.filename ? thumbnail.filename : `https://img.youtube.com/vi/${id}/hqdefault.jpg`
  const embedURL = `https://www.youtube.com/embed/${id}?enablejsapi=1&autoplay=1`

  return (
    <>
      {!videoPlay ? (
        <>
          <div className="youtube-thumbnail relative cursor-pointer z-40" onClick={() => setVideoPlay(true)}>
            <img src={thumbnailURL} alt="YouTube Video" className="aspect-video w-full object-cover" />
            <PlayButton
              className="play-video absolute scale-75 sm:scale-100 top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 cursor-pointer text-secondary hover:text-tertiary"
              title="Play video"
            />
          </div>
          <button className="sr-only" onClick={() => setVideoPlay(true)}>
            Load YouTube Video
          </button>
        </>
      ) : (
        <iframe
          className={YTVideoStyles}
          src={embedURL}
          allow="accelerometer; clipboard-write; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      )}
      <Script type="application/ld+json" id={`youtube-video-schema-${_uid}`}>
        {`{
          "@context": "https://schema.org",
          "@type": "VideoObject",
          "name": ${title ? JSON.stringify(title) : "Youtube Video"},
          "description": "${description ?? ""}",
          "thumbnailUrl": "${thumbnailURL}",
          "uploadDate": "${new Date()}",
          "contentUrl": "${embedURL}"
        }`}
      </Script>
    </>
  )
}
