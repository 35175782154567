import Breadcrumbs from "components/Breadcrumbs"
import Image from "components/Image"
import NextImage from "next/image"
import DynamicVideo from "components/DynamicVideo"
import richText from "utilities/richText"
import getSbImageDimensions from "utilities/getSbImageDimensions.ts"
import cn from "classnames"

export default function StandardPageHeader({ blok }) {
  let width, height, isResponsive

  if (blok?.image?.filename && blok?.image?.filename !== "") {
    width = blok?.image?.filename.startsWith("https://a-us.storyblok.com/")
      ? getSbImageDimensions("width", blok?.image?.filename)
      : 400
    height = blok?.image?.filename.startsWith("https://a-us.storyblok.com/")
      ? getSbImageDimensions("height", blok?.image?.filename)
      : 400
    isResponsive = height > 300 && width > 300
  }

  return (
    <header
      className={cn("md:py-12 relative overflow-hidden bg-primary", {
        "py-12": !blok?.image?.filename && !blok?.video?.length > 0,
      })}
    >
      {blok?.content?.content?.length > 1 ? (
        <>
          <NextImage
            alt=""
            src="/assets/blue-weave.webp"
            quality={80}
            placeholder="blur"
            blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mMUUvGoBwABzQD/+RWwWwAAAABJRU5ErkJggg=="
            fill
            sizes="100vw"
            style={{
              objectFit: "cover",
            }}
            priority
            aria-hidden
          />
        </>
      ) : (
        <>
          <NextImage
            alt=""
            src="/assets/blue-weave-crop.webp"
            quality={80}
            placeholder="blur"
            blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mMUUvGoBwABzQD/+RWwWwAAAABJRU5ErkJggg=="
            fill
            sizes="100vw"
            style={{
              objectFit: "cover",
            }}
            priority
            aria-hidden
          />
        </>
      )}
      <div className="px-5 py-7">
        <div className="flex flex-col md:flex-row md:gap-x-4 max-w-screen-xl mx-auto">
          {blok?.image?.filename && blok?.image?.filename !== "" ? (
            <div className="w-full md:order-last flex items-center justify-center md:basis-1/2">
              <Image
                src={blok?.image?.filename}
                alt={blok.image.alt}
                height={height}
                width={width}
                sizes={isResponsive ? "(max-width: 768px) 100vw, (max-width: 1280px) 50vw, 33vw" : null}
              />
            </div>
          ) : null}
          {blok?.video?.length > 0 ? (
            <div className="w-full md:order-last md:flex items-center md:basis-1/2">
              <DynamicVideo
                component={blok.video[0].component}
                {...blok.video[0]}
                title={blok?.video[0]?.title ?? blok?.heading}
              />
            </div>
          ) : null}
          <div
            className={cn("z-10", {
              "md:basis-1/2 flex flex-col justify-center": blok?.image?.filename || blok?.video?.length > 0,
            })}
          >
            <Breadcrumbs breadcrumbs={blok?.breadcrumbs} />
            <h1 className="text-white py-2 capitalize">{blok.heading}</h1>
            {blok.subheading && <h3 className="text-white pb-3">{blok.subheading}</h3>}
            {blok.content && (
              <div className="text-white prose-headings:text-white prose-headings:pb-4">{richText(blok.content)}</div>
            )}
          </div>
        </div>
      </div>
    </header>
  )
}
